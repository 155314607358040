import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import Footer from '../components/Footer';
import HeaderMain from '../components/HeaderMain';
import HeroSingle from '../components/pages/HeroSingle';
import '../styles/app.scss';

const Membership = ({ data }) => {
    const { wordpressPage: post } = data;

    const test_title = post.yoast_title;
    const new_seo_title = test_title.replace('&#039;', "'");

    return (
        <>
            <SEO
                title={new_seo_title}
                meta={post.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[0].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />
            <section className="smallestwdt memberships display_none">
                <div
                    className=""
                    dangerouslySetInnerHTML={{
                        __html: post.content,
                    }}
                />
            </section>
            <div className="smallestwdt memberships">
               
                <div className="margin-padding_div"></div>
                <div className="first_div-style">
                    <h2>Rockstar Memberships</h2>
                    <p className='first_div-style-para' style={{ textAlign:'center' }}>For those who love to play a bit longer and more often. </p>
                    <p className='first_div-style-para' style={{ textAlign: 'center' }}>Full Use of Our Indoor Sensory-Safe Play Gym. Guitar Key Tag for Rapid Check-In's. Gym features therapy equipment, an arts & crafts area, a calming room, and more. Children must be accompanied by a guardian and supervised at all times. </p>
                    <p className='first_div-style-para' style={{ textAlign: 'center' }}>All Prices Inclusive of Taxes and Fees.</p>
                </div>
                <img className="linemar marg-liner mobs display_none" src="https://werockthespectrumatlantagym.wrtsfranchise.com/wp-content/uploads/2020/06/linefull.jpg" alt="linefull" />
                <h3 className='h3-hidden display_none'>Memberships</h3>

                <div className="main-flex_members display_none">
                    <div class="single-row_members"><div class="child_members first-child_members"></div><div class="child_members yell_floo">Rockstar</div><div class="child_members yell_floo">Rockstar Platinum</div></div>
                    <div class="single-row_members"><div class="child_members first-child_members yell_floo">Open Play</div><div class="child_members ">2 Hours Each Visit</div><div class="child_members ">Unlimited Open Play Each Visit</div></div>
                    <div class="single-row_members"><div class="child_members first-child_members yell_floo">Numbers of Visits</div><div class="child_members ">Unlimited</div><div class="child_members ">Unlimited</div></div>
                    <div class="single-row_members"><div class="child_members first-child_members yell_floo">Additional Class</div><div class="child_members ">10% Off Scheduled Classes*</div><div class="child_members ">10% Off Scheduled Classes*</div></div>
                    <div class="single-row_members"><div class="child_members first-child_members yell_floo">Kid Friendly Snack</div><div class="child_members ">-</div><div class="child_members ">Included</div></div>
                    <div class="single-row_members"><div class="child_members first-child_members yell_floo">Guest Pass</div><div class="child_members ">1 Guest Pass Each Month</div><div class="child_members ">1 Guest Pass Each Week</div></div>
                    <div class="single-row_members"><div class="child_members first-child_members yell_floo">Special Discounts</div><div class="child_members ">-</div><div class="child_members ">10% Off Rock Shop and Special Events*</div></div>
                </div>
                <p className='small_para stared display_none'>*Exclusions apply. Zumbini, Social Skills excluded.</p>
                <img className="linemar marg-liner" src="https://werockthespectrumatlantagym.wrtsfranchise.com/wp-content/uploads/2020/06/linefull.jpg" alt="linefull" />



                <div className="new-mg-bott"
                    dangerouslySetInnerHTML={{
                        __html: `<div class="elfsight-app-85d74316-d2ff-4e0c-86c0-3a260b024987" data-elfsight-app-lazy></div>`,
                    }}
                />

                <div className="main-flex_rockstars display_none">
                    <div className="column-flex_rockstars display_none">
                       
                        <h3>Daily Rockstar</h3>
                        <p>Get to know the Gym and our Class of the day<br />
                            Valid for 1 day only<br />
                            Kid Friendly Snack Included<br />
                            Free Wifi</p>
                        <h5>1 Child -  <div
                            className="healwrap small_heals"
                            dangerouslySetInnerHTML={{
                                __html:
                                    post.acf.healcode_daily,
                            }} 
                        /></h5>
                        <h5>2 Children - <div
                            className="healwrap small_heals"
                            dangerouslySetInnerHTML={{
                                __html:
                                    post.acf.healcode_daily_two,
                            }}
                        /></h5>
                        <h5>3 Children - <div
                            className="healwrap small_heals"
                            dangerouslySetInnerHTML={{
                                __html:
                                    post.acf.healcode_daily_three,
                            }}
                        /></h5>
                        
                            </div>
                            <div className="column-flex_rockstars">
                                <h3>Rockstar Platinum</h3>
                        <p>Unlimited Access to the gym<br />
                            Valid for 3 Months<br />
                            Kid Friendly Snack Included<br />
                            10% off Schedule Classes, Rock Shop, and Special Events<br />
                            1 Guest Pass Every Week<br />
                            Free Wifi</p>
                        <h5>1 Child - <div
                            className="healwrap small_heals"
                            dangerouslySetInnerHTML={{
                                __html:
                                    post.acf.healcode_platinum_m_one,
                            }}
                        /> or <div
                                className="healwrap small_heals"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        post.acf.healcode_platinum_w,
                                }}
                            /></h5>
                        <h5>2 Children - <div
                            className="healwrap small_heals"
                            dangerouslySetInnerHTML={{
                                __html:
                                    post.acf.healcode_platinum_m_two,
                            }}
                        /> or <div
                                className="healwrap small_heals"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        post.acf.healcode_platinum_w_two,
                                }}
                            /></h5>
                        <h5>3 Children - <div
                            className="healwrap small_heals"
                            dangerouslySetInnerHTML={{
                                __html:
                                    post.acf.healcode_platinum_m_three,
                            }}
                        /> or <div
                                className="healwrap small_heals"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        post.acf.healcode_platinum_w_three,
                                }}
                            /></h5>
                                
                                            </div>
                                            <div className="column-flex_rockstars">
                                                <h3>Rockstar</h3>
                        <p>Unlimited 2 Hour Drop-in's for Open Play each Month<br />
                            Valid for 3 Months<br />
                            1 Guest Pass Every Month<br />
                            Free Wifi</p>
                        <h5>1 Child - <div
                            className="healwrap small_heals"
                            dangerouslySetInnerHTML={{
                                __html:
                                    post.acf.healcode_rockstar_m_one,
                            }}
                        /> or <div
                                className="healwrap small_heals"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        post.acf.healcode_daily_w,
                                }}
                            /></h5>
                        <h5>2 Children - <div
                            className="healwrap small_heals"
                            dangerouslySetInnerHTML={{
                                __html:
                                    post.acf.healcode_rockstar_m_two,
                            }}
                        /> or <div
                                className="healwrap small_heals"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        post.acf.healcode_w_two_copy,
                                }}
                            /></h5>
                        <h5>3 Children - <div
                            className="healwrap small_heals"
                            dangerouslySetInnerHTML={{
                                __html:
                                    post.acf.healcode_rockstar_m_three,
                            }}
                        /> or <div
                                className="healwrap small_heals"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        post.acf.healcode_rock_w_three,
                                }}
                            /></h5>
                                               
                                                    </div>
                                                </div>

                                                    <section className="whywelist page-section bg-primary text-white" id="openpbasic">
                                                        <div class="container flexwrap whywebluelist">
                        <div class="section_memberships whylistrgt flexbox toup"><h2 className="yellowtxt">MEMBERSHIP BENEFITS</h2>
                                                                <ul class="startxt normalfontlist">
                                                                    <li>All memberships include unlimited visits to the gym. 2 Hours or Unlimited Play depending on Membership Levels. </li>
                                                                    <li>Bring Along a Friend or a Cousin every Week or Every Month depending on Membership Levels. </li>
                                                                    <li>1 Kid Friendly Snack avaiable during each visit for certain members. </li>
                                                                    <li>Scheduled Classes Include - Music Class, Arts and Craft Class, Dance Class and Much More. Check Out the Latest Schedule <a href="/classes/">Here</a>.</li>
                                                                    <li>A parent or guardian must accompany the child during the visit at all times.</li>
                                                                    <li>No shoes allowed on the gym floor. Socks are required for adults. For your convenience, we also have them available to purchase.</li>
                                                                    <li>3 Month Membership Period. Membership Auto-Renews each Month. </li>
                                                                    <li>30 Day Cancellation notification required.</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </section>

                                                    <section className="page-section bg-secondary text-white centersec para_para">
                                                        <div className="container smallestwdt"><h2 class="yellowtxt">Open Play Memberships</h2>
                                                            <p className="biggertxt"><p>Learn More about Open Play Drop-In and Passes <a href="/open-play/">Here</a>.</p>
                                                            </p></div>
                                                    </section>
               
            </div>


            <Footer />
        </>
    );
};
export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            content
            slug
            acf {
                healcode_daily
                healcode_daily_two
                healcode_daily_three
                healcode_daily_w
                healcode_w_two_copy
                healcode_rock_w_three
                healcode_rockstar_m_one
                healcode_rockstar_m_two
                healcode_rockstar_m_three
                healcode_platinum_w
                healcode_platinum_w_two
                healcode_platinum_w_three
                healcode_platinum_m_one
                healcode_platinum_m_two
                healcode_platinum_m_three
            }
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
        }
    }
`;

export default Membership;
